const burgerButton = document.querySelector('.js-burger');
const aside = document.querySelector('.js-aside');
const header = document.querySelector('.js-header');

if (burgerButton) {
  const open = burgerButton.querySelector('.js-burger__open');
  const close = burgerButton.querySelector('.js-burger__close');

  burgerButton.addEventListener('click', function (event) {
    event.preventDefault();

    if (aside.classList.contains('is-active')) {
      aside.classList.remove('is-active');
      header.classList.remove('is-burger');
      close.classList.remove('is-active');
      open.classList.add('is-active');
      // header.classList.remove('b-header--white');
      //header.classList.remove('b-header--white');
    } else {
      aside.classList.add('is-active');
      header.classList.add('is-burger');
      open.classList.remove('is-active');
      close.classList.add('is-active');
      // header.classList.add('b-header--white');
      //header.classList.add('b-header--white');
    }

    if (burgerButton.classList.contains('is-active')) {
      burgerButton.classList.remove('is-active');
      burgerButton.setAttribute('aria-expanded', 'false');
    } else {
      burgerButton.classList.add('is-active');
      burgerButton.setAttribute('aria-expanded', 'true');
    }
  });
}
