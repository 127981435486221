/*! Agile Pixel https://agilepixel.io - 2022*/

import camelCase from './camelCase';


const router = (function () {
  let routes = {};
  const setRoutes = function (newroutes) {
    routes = newroutes;
  };

  const fire = function (route, function_, arguments_) {
    if (function_ === undefined) {
      function_ = 'init';
    }
    const fire =
      route !== '' &&
      routes[route] &&
      typeof routes[route][function_] === 'function';
    const lazyimport =
      route !== '' && routes[route] && typeof routes[route] === 'string';
    if (fire) {
      routes[route][function_](arguments_);
    } else if (lazyimport) {
      import(
        /* webpackChunkName: "[request]" */ `../routes/${routes[route]}`
      ).then(function (module) {
        if (typeof module[function_] === 'function') {
          module[function_]();
        } else if (typeof module.default[function_] === 'function') {
          module.default[function_]();
        }
      });
    }
  };

  const loadEvents = function () {
    // Fire common init JS
    fire('common');

    // Fire page-specific init JS, and then finalize JS
    for (const className of document.body.className
      .toLowerCase()
      .replace(/-/g, '_')
      .split(/\s+/)
      .map(camelCase)) {
      fire(className);
      fire(className, 'finalize');
    }

    // Fire common finalize JS
    fire('common', 'finalize');
  };
  return {
    setRoutes,
    fire,
    loadEvents,
  };
})();
export default router;